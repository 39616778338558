#countdown {
    width: 250px;
    padding: 10px;
    /*background-color: rgba(0, 0, 0, 0.1);*/
    border: 1px solid #bdbdbd;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
}
#countdown .box {
    padding: 10px;
    border-right: solid 1px #bdbdbd;
}
#countdown .col-4:last-child .box {
    border-right-color: transparent;
}
#countdown .box p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}
#countdown .box .text {
    font-size: 12px;
    font-family: sans-serif;
    color: #9E9E9E
}

@media (min-width: 768px) {
    .container {
        width: 1100px;
    }
    h1 {
        font-size: 58px;
    }
    #countdown {
        width: 350px;
        padding: 20px;
    }
}
